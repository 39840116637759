/*
#***********************************************
#
#      Filename: src/api/blackList/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 月租名单列表
#        Create: 2021-11-23 00:02:38
# Last Modified: 2021-11-26 13:56:29
#***********************************************
*/
import request from '@/utils/request'
import qs from 'qs'

// 添加
export function add(data) {
  const query = qs.stringify(data)
  return request({
    url: '/pmVip/legacy/add',
    method: 'post',
    data: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    }
  })
}

// 编辑
export function update(data) {
  const query = qs.stringify(data)
  return request({
    url: '/pmVip/legacy/edit',
    method: 'post',
    data: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    }
  })
}

// 新增月租缴费
export function addVipOrder(data) {
  const formData = qs.stringify(data)
  return request({
    url: '/pmVip/legacy/addVipOrder',
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 删除
export function deleteItem(id) {
  return request({
    url: '/pmVip/legacy/delete/' + id,
    method: 'post'
  })
}

// 批量删除
export function batchDelete(data) {
  const formData = qs.stringify(data)
  return request({
    url: '/pmVip/legacy/batchdel',
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 导出
export function exportRecord(data) {
  return request({
    url: '/pmVip/legacy/export',
    method: 'get',
    params: data
  })
}

// 详细信息
export function info(id) {
  return request({
    url: '/pmVip/legacy/info/' + id,
    method: 'get'
  })
}

// 列表信息
export function list(data) {
  return request({
    url: '/pmVip/legacy/list',
    method: 'get',
    params: data
  })
}

// 月租名单导入
export function upload(data) {
  const formData = qs.stringify(data)
  return request({
    url: '/pmVip/legacy/uploadExcel',
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}
