<!--
#***********************************************
#
#      Filename: src/views/List/BlackList.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 月租名单
#        Create: 2021-10-28 16:38:32
# Last Modified: 2021-11-29 18:02:17
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      :import-actions="importActions"
      :permissions="permissions"
      @filter-change="filterChange"
      @add="add('新增月租名单')"
      @refresh="handleRefresh"
      @export="handleExport"
      @download="handleDownload"
      @import-success="handleImportSuccess"
      @batch-delete="handleBatchDelete('批量删除月租名单', handleBatchDel)" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" stripe border size="mini" @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop" />
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop" />
      <el-table-column
        v-if="tableHeadFields[2].isShow"
        :label="tableHeadFields[2].label"
        :width="tableHeadFields[2].width"
        :align="tableHeadFields[2].align"
        :prop="tableHeadFields[2].prop" />
      <el-table-column
        v-if="tableHeadFields[3].isShow"
        :label="tableHeadFields[3].label"
        :width="tableHeadFields[3].width"
        :align="tableHeadFields[3].align"
        :prop="tableHeadFields[3].prop">
        <template slot-scope="scope">
          {{ parseTime(scope.row.startTime) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[4].isShow"
        :label="tableHeadFields[4].label"
        :width="tableHeadFields[4].width"
        :align="tableHeadFields[4].align"
        :prop="tableHeadFields[4].prop">
        <template slot-scope="scope">
          {{ parseTime(scope.row.endTime) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[5].isShow"
        :label="tableHeadFields[5].label"
        :width="tableHeadFields[5].width"
        :align="tableHeadFields[5].align"
        :prop="tableHeadFields[5].prop" />
      <el-table-column
        v-if="tableHeadFields[6].isShow"
        :label="tableHeadFields[6].label"
        :width="tableHeadFields[6].width"
        :align="tableHeadFields[6].align"
        :prop="tableHeadFields[6].prop" />
      <el-table-column
        v-if="tableHeadFields[7].isShow"
        :label="tableHeadFields[7].label"
        :width="tableHeadFields[7].width"
        :align="tableHeadFields[7].align"
        :prop="tableHeadFields[7].prop" />
      <el-table-column
        v-if="tableHeadFields[8].isShow"
        :label="tableHeadFields[8].label"
        :width="tableHeadFields[8].width"
        :align="tableHeadFields[8].align"
        :prop="tableHeadFields[8].prop" />
      <el-table-column
        v-if="tableHeadFields[9].isShow"
        :label="tableHeadFields[9].label"
        :width="tableHeadFields[9].width"
        :align="tableHeadFields[9].align"
        :prop="tableHeadFields[9].prop" />
      <el-table-column
        v-if="tableHeadFields[10].isShow"
        :label="tableHeadFields[10].label"
        :width="tableHeadFields[10].width"
        :align="tableHeadFields[10].align"
        :prop="tableHeadFields[10].prop" />
      <el-table-column
        v-if="tableHeadFields[11].isShow"
        :label="tableHeadFields[11].label"
        :width="tableHeadFields[11].width"
        :align="tableHeadFields[11].align"
        :prop="tableHeadFields[11].prop" />
      <el-table-column label="操作" :width="300" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="gz-text-btn-group">
            <div
              v-has-permi="[permissions.update]"
              class="gz-text-btn gz-text-btn-yellow"
              @click="edit(scope.row, '修改月租名单', beforeEdit)">
              编辑
            </div>
            <div
              v-has-permi="[permissions.offlinePay]"
              class="gz-text-btn gz-text-btn-yellow"
              @click="handelPay(scope.row)">
              缴月租费
            </div>
            <div
              v-has-permi="[permissions.delete]"
              class="gz-text-btn gz-text-btn-red"
              @click="handleDelete(scope.row.id, '删除月租名单', handleDel)">
              删除
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->

    <!-- 添加修改dialog -->
    <el-dialog
      :title="modalObject.title"
      :visible.sync="modalObject.status"
      append-to-body
      width="868px"
      class="gz-common-dialog"
      @close="handleClose">
      <el-form ref="form" label-width="100px" :model="actionDataItem" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="姓名:" prop="name">
              <el-input v-model="actionDataItem.name" placeholder="请输入业主姓名" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="电话:" prop="phone">
              <el-input v-model="actionDataItem.phone" placeholder="请输入业主电话号码" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="车牌号:" prop="carNumber">
              <el-input v-model="actionDataItem.carNumber" placeholder="请输入车牌号码" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="房间号:" prop="roomNum">
              <el-input v-model="actionDataItem.roomNum" placeholder="请输入房间号码" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="单元:" prop="element">
              <el-input v-model="actionDataItem.element" placeholder="请输入单元号" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="楼号:" prop="tower">
              <el-input v-model="actionDataItem.tower" placeholder="请输入楼号/子科室/部门" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="区号:" prop="region">
              <el-input v-model="actionDataItem.region" placeholder="请输入区号/科室/部门/公司" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车辆类型:" prop="carType">
              <el-select v-model="actionDataItem.carType" placeholder="请选择车辆类型">
                <el-option label="职工车辆" :value="1" />
                <el-option label="家属车辆" :value="2" />
                <el-option label="公务车辆" :value="3" />
                <el-option label="社会车辆" :value="4" />
                <el-option label="军警车辆" :value="5" />
                <el-option label="业主" :value="7" />
                <el-option label="商户" :value="8" />
                <el-option label="服务费" :value="9" />
                <el-option label="地下包月" :value="10" />
                <el-option label="地上包月" :value="11" />
                <el-option label="其他" :value="6" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- end 添加修改dialog -->

    <!-- 月租缴费dialog -->
    <el-dialog
      :title="payModalObject.title"
      :visible.sync="payModalObject.status"
      append-to-body
      width="868px"
      class="gz-common-dialog"
      @close="handlePayModalClose">
      <el-form ref="payform" label-width="100px" :model="payModalObject.data" :rules="payModalObject.rules">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="车牌号:" prop="carNumber">
              <el-input v-model="payModalObject.data.carNumber" placeholder="请输入缴费车牌号" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="缴费金额:" prop="price">
              <el-input v-model="payModalObject.data.price" placeholder="请输入缴费金额" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="缴费类型:" prop="payType">
              <el-select v-model="payModalObject.data.payType" placeholder="请选择缴费类型">
                <el-option label="微信" :value="1" />
                <el-option label="现金" :value="4" />
                <el-option label="工资扣款" :value="6" />
                <el-option label="业主" :value="7" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="生效时间" prop="startTime">
              <el-date-picker
                v-model="payModalObject.data.startTime"
                type="date"
                value-format="timestamp"
                placeholder="请选择生效时间" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="失效时间" prop="endTime">
              <el-date-picker
                v-model="payModalObject.data.endTime"
                type="date"
                value-format="timestamp"
                placeholder="请选择失效时间" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitPayForm">确 定</el-button>
        <el-button @click="cancelPayForm">取 消</el-button>
      </div>
    </el-dialog>
    <!-- end 月租缴费dialog -->
    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import moment from 'moment'
  import { list, add, addVipOrder, deleteItem, batchDelete, exportRecord, update } from '@/api/monthList' // eslint-disable-line
  const { export_json_to_excel } = require('../../../vendor/Export2Excel')

  export default {
    name: 'MonthList',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        /**
         * 缴月租费弹框控制数据
         */
        payModalObject: {
          title: '缴月租费',
          status: false,
          data: {
            carNumber: '',
            price: '0.00',
            payType: '',
            startTime: null,
            endTime: null
          },
          rules: {
            carNumber: [{ required: true, message: '车牌号不能为空', trigger: 'blur' }],
            price: [{ required: true, message: '缴费金额不能为空', trigger: 'blur' }],
            payType: [{ required: true, message: '缴费类型不能为空', trigger: 'blur' }],
            startTime: [{ required: true, message: '生效时间不能为空', trigger: 'blur' }],
            endTime: [
              { required: true, message: '失效时间不能为空', trigger: 'blur' },
              {
                validator: (rule, value, callback) => {
                  const startTime = this.payModalObject.data.startTime
                  if (value < startTime) {
                    callback(new Error('失效时间不能在生效时间之前'))
                  } else {
                    callback()
                  }
                },
                trigger: 'blur'
              }
            ]
          }
        },

        /**
         * 账号添加修改表单数据绑定
         */
        formData: {
          id: '',
          name: '',
          phone: '',
          carNumber: '',
          element: '',
          tower: '',
          region: '',
          carType: '',
          roomNum: '',
          timeRange: []
        },

        /**
         * 状态添加修改表单数据验证规则
         */
        rules: {
          name: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
          carNumber: [{ required: true, message: '请输入车牌号码', trigger: 'blur' }],
          carType: [{ required: true, message: '请选择车辆类型', trigger: 'blur' }]
        },

        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: true,
            tableHeadToggleShow: true,
            searchPlaceholder: '车牌号'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: true,
            exportDataShow: true,
            importDataShow: true,
            downloadTemplateShow: true,
            more: [
              {
                label: '批量删除',
                emitEvent: 'batch-delete'
              }
            ]
          },
          dropDownFilterArray: {
            arr: [
              {
                type: 'input',
                name: 'name',
                placeholder: '请输入姓名'
              },
              {
                type: 'input',
                name: 'phone',
                placeholder: '请输入联系电话'
              },
              {
                type: 'input',
                name: 'region',
                placeholder: '请输入区号/科室/部门/公司'
              },
              {
                type: 'input',
                name: 'tower',
                placeholder: '请输入楼号/子科室/部门'
              },
              {
                type: 'input',
                name: 'element',
                placeholder: '请输入单元号'
              },
              {
                type: 'input',
                name: 'roomNum',
                placeholder: '请输入房间号'
              },
              {
                type: 'select',
                name: 'carType',
                placeholder: '请选择车辆类型',
                options: [
                  { label: '职工车辆', value: '1' },
                  { label: '家属车辆', value: '2' },
                  { label: '公务车辆', value: '3' },
                  { label: '社会车辆', value: '4' },
                  { label: '军警车辆', value: '5' },
                  { label: '业主', value: '7' },
                  { label: '商户', value: '8' },
                  { label: '服务费', value: '9' },
                  { label: '地下包月', value: '10' },
                  { label: '地上包月', value: '11' },
                  { label: '其他', value: '6' }
                ]
              },
              {
                type: 'select',
                name: 'status',
                placeholder: '请选择状态',
                options: [
                  { label: '正常', value: '1' },
                  { label: '已过期', value: '2' }
                ]
              }
            ]
          }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '姓名',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'name'
          },
          {
            label: '电话',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'phone'
          },
          {
            label: '车牌号',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'carNumber'
          },
          {
            label: '开始时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'startTime'
          },
          {
            label: '结束时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'endTime'
          },
          {
            label: '区号/科室/部门/公司',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'region'
          },
          {
            label: '楼号/子科室/部门',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'tower'
          },
          {
            label: '单元号',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'element'
          },
          {
            label: '房间号',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'roomNum'
          },
          {
            label: '状态',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'statusCN'
          },
          {
            label: '车辆类型',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'carTypeCN'
          },
          {
            label: '备注',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'remark'
          }
        ],

        /**
         * 权限信息
         */
        permissions: {
          create: 'parking:vip:create',
          info: 'parking:vip:info',
          delete: 'parking:vip:delete',
          update: 'parking:vip:update',
          export: 'parking:vip:export',
          import: 'parking:vip:import',
          offlinePay: 'parking:vip:offline-pay'
        }
      }
    },

    computed: {
      importActions() {
        return process.env.VUE_APP_BASE_API + '/pmVip/legacy/uploadExcel?pId=' + this.pid + '&operId=' + this.uid
      }
    },

    mounted() {
      this.getList()
    },

    methods: {
      handlePayModalClose() {
        this.payModalObject.data = {
          carNumber: '',
          price: '0.00',
          payType: '',
          startTime: null,
          endTime: null
        }
      },

      /**
       * 月租缴费弹框确认
       */
      submitPayForm() {
        const _this = this
        this.$refs.payform.validate((valid) => {
          if (valid) {
            const formData = this.payModalObject.data
            formData.pId = this.pid
            formData.startTime = moment(this.payModalObject.data.startTime).format('YYYY-MM-DD')
            formData.endTime = moment(this.payModalObject.data.endTime).format('YYYY-MM-DD')
            addVipOrder(formData)
              .then((res) => {
                _this.noticeSuccess('缴费成功')
                _this.handleRefresh()
              })
              .catch((e) => {
                _this.noticeError(e.message)
              })
              .finally(() => {
                _this.payModalObject.status = false
              })
          } else {
            return false
          }
        })
      },

      /**
       * 月租缴费弹框取消
       */
      cancelPayForm() {
        this.payModalObject.data = {
          id: '',
          carNumber: '',
          price: '0.00',
          payType: '',
          startTime: null,
          endTime: null
        }
        this.payModalObject.status = false
      },

      /**
       * 点击缴纳月租费按钮
       */
      handelPay(data) {
        this.payModalObject.data.id = data.id
        this.payModalObject.data.carNumber = data.carNumber
        if (data.startTime) {
          this.payModalObject.data.startTime = data.startTime * 1000
        }
        if (data.endTime) {
          this.payModalObject.data.endTime = data.endTime * 1000
        }
        this.payModalObject.data.price = data.price
        this.payModalObject.payType = data.payType
        this.payModalObject.status = true
      },

      /**
       * 编辑前预处理
       */
      beforeEdit() {
        console.log('编辑前预处理')
      },

      /**
       * 获取列表数据
       */
      getList() {
        if (this.pid) {
          // 组装查询条件
          const queryData = this.filterData
          queryData.pId = this.pid
          queryData.current = this.currentPage
          queryData.size = this.pageSize
          queryData.carNumber = this.filterData.searchStr

          list(queryData)
            .then((res) => {
              console.log('res', res)
              this.dataList = res.data.records
              this.total = res.data.total
            })
            .catch((err) => {
              console.error('月租名单列表查询出错', err)
            })
        }
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      },

      /**
       * 提交信息
       */
      submitForm() {
        const _this = this
        this.$refs.form.validate((valid) => {
          if (valid) {
            if (_this.modalObject.currentAction === 'edit') {
              _this.actionDataItem.pId = _this.pid
              update(_this.actionDataItem)
                .then((res) => {
                  if (res.result) {
                    _this.noticeSuccess(res.message)
                    _this.modalObject.status = false
                    _this.handleRefresh()
                  }
                })
                .catch((e) => {
                  console.log(e)
                })
            } else {
              _this.actionDataItem.pId = _this.pid
              add(_this.actionDataItem)
                .then((res) => {
                  if (res.result) {
                    _this.handleRefresh()
                    _this.noticeSuccess(res.message)
                    _this.modalObject.status = false
                  }
                })
                .catch((e) => {
                  _this.noticeError(e.message)
                })
            }
          }
        })
      },

      /**
       * 删除一条记录
       */
      handleDel(id) {
        const _this = this
        deleteItem(id)
          .then((res) => {
            _this.handleRefresh()
          })
          .catch((err) => {
            _this.msgError(err.message)
          })
      },

      /**
       * 批量删除记录
       */
      handleBatchDel(ids) {
        const _this = this
        const idArr = []
        ids.forEach((item) => {
          idArr.push(item.id)
        })
        const postData = {}
        postData.ids = idArr.join(',')
        batchDelete(postData)
          .then((res) => {
            _this.handleRefresh()
          })
          .catch((err) => {
            _this.msgError(err.message)
          })
      },

      /**
       * 导出数据
       */
      handleExport() {
        const _this = this
        this.$confirm('是否导出月租名单列表，最多导出500条数据？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            // 组装查询条件
            const queryData = this.filterData
            queryData.pId = this.pid
            queryData.current = this.currentPage
            queryData.size = 500
            queryData.carNumber = this.filterData.searchStr

            exportRecord(queryData)
              .then((res) => {
                require.ensure([], () => {
                  const tHeader = []
                  const filterVal = []
                  this.tableHeadFields.forEach((item) => {
                    tHeader.push(item.label)
                    filterVal.push(item.prop)
                  })
                  const data = res.data.map((v) => {
                    return filterVal.map((j) => {
                      if (j === 'startTime') {
                        return _this.parseTime(v[j])
                      } else if (j === 'endTime') {
                        return _this.parseTime(v[j])
                      } else {
                        return v[j]
                      }
                    })
                  })
                  export_json_to_excel(tHeader, data, '月租名单列表')
                })
                _this.noticeSuccess('导出成功')
              })
              .catch((err) => {
                _this.noticeError(err.message)
              })
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
      },

      /**
       * 上传成功
       */
      handleImportSuccess() {
        this.noticeSuccess('上传成功')
        this.handleRefresh()
      },

      /**
       * 下载模板
       */
      handleDownload() {
        window.open('http://matrixoss.oss-cn-zhangjiakou.aliyuncs.com/OPO/data/template/pmVip.xls')
      },

      /**
       * 添加编辑模态框关闭触发事件
       */
      handleClose() {
        this.$refs.form.resetFields()
      }
    }
  }
</script>
<style scoped lang="scss">
  .gz-dynamtic-input-wrapper {
    display: grid;
    grid-column-gap: 20px;
  }
  ::v-deep .el-date-editor {
    width: 100%;
  }
</style>
